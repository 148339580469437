import React from 'react';
import { reducers } from '../store/configureShopStore';
import { BrowserRouter, Route, Routes, useLocation, useParams, } from "react-router-dom";

import NoMatch from './NoMatch';
import ErrorBoundary from './ErrorBoundary';
import { getQueryParams } from '../utils';
import PublicOrderShopApp from '../containers/PublicOrderShopApp';
import withReducers from '../store/withReducers';

const AppRoute = React.memo(({ panel }) => {
  const params = useParams();
  const location = useLocation();
  const queryParams = getQueryParams({ location });

  if (panel) {
    return <PublicOrderShopApp
      panel={panel}
      params={{ ...queryParams, ...params }}
      location={location}
    />;
  }

  return <NoMatch location={location} />;
});

const PublicOrderShopRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/order/:order_id"
        element={<AppRoute panel="home" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/order/:order_id/shop"
        element={<AppRoute panel="shop" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/order/:order_id/shop/:item_id"
        element={<AppRoute panel="shop" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/order/:order_id/cart"
        element={<AppRoute panel="cart" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/order/:order_id/checkout"
        element={<AppRoute panel="checkout" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/order/:order_id/receipt"
        element={<AppRoute panel="receipt" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default withReducers(PublicOrderShopRouter, reducers, true);
